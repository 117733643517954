import { string, mixed } from 'libs/validation';

export const staticValidationFields = {
    productId: string().trim().required(),
    selectedAccount: string().trim().required(),
    language: string().trim().required(),
    caseTitle: string().trim().min(2).max(200).required(),
    caseDescription: string().trim().max(20000).required(),
    attachments: mixed().attachments(),
};

export const conditionalValidationFields = {
    productVersion: string().trim().min(1).max(100).required(),
    operatingSystem: string().trim().required(),
};

const validationSchema = {
    ...staticValidationFields,
    ...conditionalValidationFields,
};

export default validationSchema;
