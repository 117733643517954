import { Dispatch, SetStateAction } from 'react';
import { useBlocker } from 'react-router-dom';

export const useFileProgressBlocker = (
    isUnsafeClose: boolean,
    setIsUnsafeClose: Dispatch<SetStateAction<boolean>>,
    files: File[]
) => {
    useBlocker(() => {
        if (isUnsafeClose) {
            // eslint-disable-next-line no-alert
            const promptConfirm = window.confirm(
                'Are you sure you want to leave this page? Changes you made may not be saved.'
            );
            if (promptConfirm) {
                files.forEach((file) => {
                    const { progress, abortController } = file as any;
                    if (progress !== 100 && abortController) {
                        abortController.abort();
                    }
                });
                setIsUnsafeClose(false);
            }
            return !promptConfirm;
        }
        return false;
    });
};
