export const removeQueryParameters = (
    url: string,
    paramsToRemove: string[]
): string => {
    const urlObject = new URL(url);
    paramsToRemove.forEach((param) => {
        urlObject.searchParams.delete(param);
    });
    return encodeURI(decodeURI(urlObject.toString()));
};
