import {
    Paper,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
    FormControl,
    InputLabel,
    Select,
    Divider,
    MenuItem,
    Skeleton,
    Checkbox,
    LinearProgress,
} from '@northstar/core';

import ListItemText from '@northstar/core/ListItemText';

import {
    Column,
    useGlobalFilter,
    usePagination,
    useFilters,
    useSortBy,
    useTable,
} from 'react-table';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatedDate } from 'utils/formatedDate';
import { TABLE_ROWS_PER_PAGE } from 'constants/tableConfig';
import { dropDownMenuProps } from 'constants/dropDownMenuProps';
import { ProductPermission } from 'types/permissions';
import { CaseData, CaseDataResponse } from 'types/cases';
import { AccountsSelection } from 'components/AccountsSelection';
import { useAccounts } from 'contexts/Accounts';
import { SelectChangeEvent } from '@northstar/core/types/Select';
import { List } from '@northstar/icons';
import NoData from 'components/DataState';

import TableToolbar from './TableToolbar';
import caseStatusList from '../json/caseStatusList.json';

interface Props {
    productsList: ProductPermission[] | undefined;
    mutateCases: () => Promise<CaseDataResponse>;
    mutateDraftCases: () => Promise<CaseDataResponse>;
    isValidating: boolean;
    caseData: CaseDataResponse;
    isLoading: boolean;
}

const columnWidths = {
    caseTitle: 216,
    caseStatus: 145,
    caseNumber: 155,
    createDate: 130,
    modifyDate: 122,
    Organization: 200,
    product: 200,
} as {
    [key: string]: string | number;
};

const multiSelectFilter = (rows: any, columnId: any, filterValue: string[]) =>
    filterValue.length === 0
        ? rows
        : rows.filter((row: any) =>
              filterValue.includes(String(row.original[columnId]))
          );

const headerColumns: Column<CaseData>[] = [
    {
        Header: 'Request Title',
        accessor: 'caseTitle',
    },
    {
        Header: 'Status',
        accessor: 'caseStatus',
        filter: multiSelectFilter,
    },
    {
        Header: 'Request Number',
        accessor: 'caseNumber',
    },
    {
        Header: 'Product',
        accessor: 'product',
        filter: multiSelectFilter,
    },

    {
        Header: 'Organization',
        accessor: (d) => {
            return d.ownerInfo.account;
        },
    },
    {
        Header: 'Submitted At',
        accessor: 'createDate',
        Cell: (cell) => (
            <span>
                {cell.row.original.caseStatus !== 'Draft'
                    ? formatedDate(cell.value).toDate()
                    : ''}
            </span>
        ),
    },
    {
        Header: 'Modified At',
        accessor: 'modifyDate',
        Cell: (cell) => <>{formatedDate(cell.value).toDate()}</>,
    },
];

const CasesList = ({
    productsList,
    caseData,
    mutateCases,
    mutateDraftCases,
    isValidating,
    isLoading = false,
}: Props) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { isSingleAccount, relatedAccounts } = useAccounts();

    if (isSingleAccount) {
        const organizationIndex = headerColumns.findIndex(
            (column) => column.Header === 'Organization'
        );

        if (organizationIndex !== -1) {
            headerColumns.splice(organizationIndex, 1);
        }
    }

    const queryStatus = searchParams.get('status') || '';
    const queryProduct = searchParams.get('product') || '';
    const querySearch = searchParams.get('search') || '';
    const queryPerPage = searchParams.get('perPage') || TABLE_ROWS_PER_PAGE[0];
    const queryPage = searchParams.get('page');

    const getQueryStatuses = () => {
        try {
            const decoded = decodeURIComponent(queryStatus).split(',');
            const newResult = [] as string[];

            caseStatusList.statusList.forEach((item) => {
                if (decoded.includes(item)) {
                    newResult.push(item);
                }
            });

            return newResult;
        } catch {
            return [];
        }
    };

    const getQueryProducts = () => {
        try {
            const decoded = decodeURIComponent(queryProduct).split(',');
            const newResult = [] as string[];

            productsList?.forEach(({ productName }) => {
                if (decoded.includes(productName)) {
                    newResult.push(productName);
                }
            });

            return newResult;
        } catch {
            return [];
        }
    };

    const selectedStatuses = queryStatus ? getQueryStatuses() : [];
    const selectedProducts = queryProduct ? getQueryProducts() : [];

    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        setGlobalFilter,
        setFilter,
        state: { pageIndex, pageSize },
        rows,
    } = useTable(
        {
            columns: headerColumns,
            data: caseData?.cases || [],
            disableSortRemove: true,
            initialState: {
                pageSize: TABLE_ROWS_PER_PAGE.includes(Number(queryPerPage))
                    ? +queryPerPage
                    : TABLE_ROWS_PER_PAGE[0],
                globalFilter: querySearch,
                pageIndex: caseData?.cases && queryPage ? +queryPage - 1 : 0,
                filters: [
                    {
                        id: 'caseStatus',
                        value: selectedStatuses,
                    },
                    {
                        id: 'product',
                        value: selectedProducts,
                    },
                ],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    const handleCasesStatusSelect = (
        event: SelectChangeEvent<typeof selectedStatuses>
    ) => {
        const {
            target: { value },
        } = event;

        const result = typeof value === 'string' ? value.split(',') : value;

        result.length
            ? searchParams.set('status', result.join(','))
            : searchParams.delete('status');

        searchParams.delete('page');
        gotoPage(0);

        setSearchParams(searchParams);
        setFilter('caseStatus', result);
    };

    const handleCasesProductChange = (
        event: SelectChangeEvent<typeof selectedProducts>
    ) => {
        const {
            target: { value },
        } = event;

        const result = typeof value === 'string' ? value.split(',') : value;

        result.length
            ? searchParams.set('product', result.join(','))
            : searchParams.delete('product');

        searchParams.delete('page');
        gotoPage(0);
        setSearchParams(searchParams);
        setFilter('product', result);
    };

    const onPageChange = (
        _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        gotoPage(newPage);
        searchParams.set('page', String(newPage + 1));
        setSearchParams(searchParams);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        searchParams.delete('page');
        gotoPage(0);
        setPageSize(Number(event.target.value));
        searchParams.set('perPage', event.target.value);
        setSearchParams(searchParams);
    };

    const handleAccountSelectionChange = () => {
        if (searchParams.get('page')) {
            searchParams.delete('page');
            gotoPage(0);
            setSearchParams(searchParams);
        }
        mutateCases();
        mutateDraftCases();
    };

    const handleCaseSelect = (rowOriginal: CaseData) => {
        const {
            id,
            ownerInfo: { accountId },
            caseStatus,
        } = rowOriginal;

        sessionStorage.setItem('selectedAccountId', accountId);

        const url =
            caseStatus === 'Draft'
                ? `/support/requests/open#${id}`
                : `/support/requests/${id}`;
        navigate(url);
    };

    const sortDirection = (direction?: boolean) => {
        return direction ? 'desc' : 'asc';
    };

    const getCellTitle = (cellId: string, value: any, caseStatus: string) => {
        const isDraft = caseStatus === 'Draft';
        const createDate = cellId === 'createDate';
        const modifyDate = cellId === 'modifyDate';
        if (createDate || modifyDate) {
            return isDraft && createDate ? '' : formatedDate(value).toDate();
        }

        return value;
    };

    return (
        <>
            <Typography
                sx={{ mb: 2 }}
                variant="h4"
                component="p"
                id="tableTitle"
            >
                Manage Support Requests
            </Typography>
            <Paper>
                <TableToolbar
                    setGlobalFilter={setGlobalFilter}
                    globalFilter={querySearch}
                />
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    sx={{ mt: 1, mb: 1, px: 2 }}
                >
                    <Typography component="span" sx={{ fontStyle: 'italic' }}>
                        Filter:
                    </Typography>
                    {relatedAccounts ? (
                        <>
                            <AccountsSelection
                                handleSelectionChange={
                                    handleAccountSelectionChange
                                }
                            />
                            <FormControl
                                sx={{
                                    my: 1,
                                    mx: [0, 2],
                                    minWidth: 120,
                                    width: ['100%', '30%', '20%'],
                                }}
                                size="small"
                            >
                                <InputLabel id="status-filter">
                                    Status
                                </InputLabel>
                                <Select
                                    fullWidth
                                    multiple
                                    MenuProps={dropDownMenuProps}
                                    sx={{ height: '40px' }}
                                    labelId="status-filter-select"
                                    id="status-filter-select"
                                    label="Status"
                                    aria-label="Request Status Select"
                                    value={selectedStatuses}
                                    onChange={handleCasesStatusSelect}
                                    renderValue={(selected) =>
                                        selected.join(', ')
                                    }
                                >
                                    {caseStatusList.statusList.map((status) => (
                                        <MenuItem value={status} key={status}>
                                            <Checkbox
                                                color="primary"
                                                checked={
                                                    selectedStatuses.indexOf(
                                                        status
                                                    ) > -1
                                                }
                                            />
                                            <ListItemText
                                                primary={status}
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    width: '100%',
                                                }}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl
                                sx={{
                                    my: 1,
                                    mx: [0, 2],
                                    minWidth: 120,
                                    width: ['100%', '50%', '30%'],
                                }}
                                size="small"
                            >
                                <InputLabel id="product-filter">
                                    Product
                                </InputLabel>
                                <Select
                                    fullWidth
                                    multiple
                                    MenuProps={dropDownMenuProps}
                                    sx={{ height: '40px' }}
                                    id="product-filter-select"
                                    label="Product"
                                    aria-label="Request Product Select"
                                    value={selectedProducts}
                                    onChange={handleCasesProductChange}
                                    renderValue={(selected) =>
                                        selected.join(', ')
                                    }
                                >
                                    {productsList && !productsList.length && (
                                        <MenuItem value="" disabled>
                                            No options
                                        </MenuItem>
                                    )}
                                    {!!productsList?.length &&
                                        productsList.map(
                                            (product: ProductPermission) => {
                                                if (
                                                    product.productName === null
                                                ) {
                                                    return null;
                                                }
                                                return (
                                                    <MenuItem
                                                        value={
                                                            product.productName
                                                        }
                                                        key={
                                                            product.softwareProductId
                                                        }
                                                    >
                                                        <Checkbox
                                                            color="primary"
                                                            checked={
                                                                selectedProducts.indexOf(
                                                                    product.productName
                                                                ) > -1
                                                            }
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                product.productName
                                                            }
                                                            sx={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                                overflow:
                                                                    'hidden',
                                                                textOverflow:
                                                                    'ellipsis',
                                                                width: '100%',
                                                            }}
                                                        />
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                </Select>
                            </FormControl>
                        </>
                    ) : (
                        <>
                            {Array.from(Array(3).keys()).map((index) => (
                                <Skeleton
                                    animation="wave"
                                    aria-label="skeleton"
                                    height={58}
                                    key={index}
                                    width={index === 1 ? '250px' : '300px'}
                                    sx={{
                                        my: 0,
                                        mx: [2, 2],
                                        width: ['100%', '280px'],
                                    }}
                                />
                            ))}
                        </>
                    )}
                </Box>
                <Divider />
                <TableContainer
                    sx={{
                        paddingX: 0.75,
                        minHeight: 587,
                        position: 'relative',
                    }}
                >
                    {isValidating && (
                        <LinearProgress
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                            }}
                        />
                    )}
                    <Table {...getTableProps()}>
                        <TableHead>
                            {headerGroups.map((headerGroup) => (
                                <TableRow
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {headerGroup.headers.map((column) => (
                                        <TableCell
                                            sx={{
                                                width:
                                                    columnWidths[column.id] ||
                                                    undefined,
                                                minWidth:
                                                    columnWidths[column.id] ||
                                                    undefined,
                                                paddingX: 1.25,
                                            }}
                                            {...(column.id === 'selection'
                                                ? column.getHeaderProps()
                                                : column.getHeaderProps(
                                                      column.getSortByToggleProps()
                                                  ))}
                                        >
                                            {column.render('Header')}
                                            {column.id !== 'selection' ? (
                                                <TableSortLabel
                                                    aria-label="Toggle SortBy"
                                                    active={column.isSorted}
                                                    direction={sortDirection(
                                                        column.isSortedDesc
                                                    )}
                                                />
                                            ) : null}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody aria-label="table-body">
                            {page.length > 0 &&
                                page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <TableRow {...row.getRowProps()} hover>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <TableCell
                                                        sx={{
                                                            cursor: 'pointer',
                                                            paddingX: 1.25,
                                                        }}
                                                        {...cell.getCellProps()}
                                                        onClick={() =>
                                                            handleCaseSelect(
                                                                row.original
                                                            )
                                                        }
                                                        title={getCellTitle(
                                                            cell.column.id,
                                                            cell.value,
                                                            cell.row.original
                                                                .caseStatus
                                                        )}
                                                    >
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                maxWidth:
                                                                    (columnWidths[
                                                                        cell
                                                                            .column
                                                                            .id
                                                                    ] as number) -
                                                                        20 ||
                                                                    undefined,
                                                                textWrap:
                                                                    'nowrap',
                                                                textOverflow:
                                                                    'ellipsis',
                                                                overflow:
                                                                    'hidden',
                                                                display:
                                                                    'block',
                                                            }}
                                                        >
                                                            {cell.render(
                                                                'Cell'
                                                            )}
                                                        </Box>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            {isLoading && (
                                <>
                                    {Array.from(Array(10).keys()).map((row) => {
                                        return (
                                            <TableRow key={row}>
                                                {headerColumns.map(
                                                    (columnItem) => (
                                                        <TableCell
                                                            key={
                                                                columnItem.Header as string
                                                            }
                                                            sx={{
                                                                paddingX: 1.25,
                                                            }}
                                                        >
                                                            <Skeleton aria-label="skeleton" />
                                                        </TableCell>
                                                    )
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                </>
                            )}
                        </TableBody>
                    </Table>
                    {(!caseData?.cases.length || !page.length) &&
                        !isLoading && (
                            <NoData
                                minHeight={530}
                                justifyContent="center"
                                title="The support request list is empty"
                                icon={List}
                            />
                        )}
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                    count={rows.length ?? 1}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
};

export default CasesList;
