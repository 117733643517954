import { MAX_FILES_TOTAL } from 'constants/file';

export const canUploadFiles = (
    uploadedFiles: File[],
    acceptedFiles: File[]
) => {
    let attachmentsTotal = 0;

    const isExceedTotal = [
        ...uploadedFiles,
        ...acceptedFiles.filter((file: any) => file.uploaded !== 'retry'),
    ].some((value) => {
        attachmentsTotal += value.size;
        if (attachmentsTotal > MAX_FILES_TOTAL) return true;
        return false;
    });

    if (isExceedTotal) {
        acceptedFiles.forEach((value) => {
            Object.assign(value, {
                status: 'error',
                uploaded: 'failed',
            });
        });
        return false;
    }

    return true;
};
