import { useEffect } from 'react';

export const useHandleBeforeUnload = <T>(isUnsafeClose: T) => {
    useEffect(() => {
        // eslint-disable-next-line consistent-return
        const handleBeforeUnload = (event: any) => {
            if (isUnsafeClose) {
                event.preventDefault();
                // eslint-disable-next-line no-param-reassign
                event.returnValue = '';
                return '';
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isUnsafeClose]);
};
