import { useState } from 'react';
import Box from '@northstar/core/Box';
import Typography from '@northstar/core/Typography';
import { Button, Tooltip } from '@northstar/core';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import Skeleton from '@northstar/core/Skeleton';
import { withAuth } from 'components/Auth';
import { formatedDate } from 'utils/formatedDate';
import { Comment } from 'types/cases';
import { ActivityItemLoading } from 'pages/Support/components/Loading/ActivityItemLoading';
import { Main } from 'components/layouts';
import { useApp } from 'contexts/App';
import { Form, ActivityItem, AttachmentList } from './components/View';
import ResolveCaseDialog from './components/View/ResolveCaseDialog';
import { PageBody, PageHeader } from './components/PageContent';

const ViewCase = () => {
    const { appConfig } = useApp();
    const [dialogOpen, setDialogOpen] = useState(false);

    const { id } = useParams();

    const { data, error } = useSWR(`/cases/${id}`);
    const { data: comments } = useSWR(data && `/cases/${id}/comments`);
    const { data: draftComment } = useSWR(`/cases/draft/${id}/comment`);

    const breadcrumbs = [
        {
            href: '/',
            title: 'Home',
            isActive: false,
        },
        {
            href: '/support/requests',
            title: 'Manage Requests',
            isActive: false,
        },
        {
            title: data?.caseNumber,
            isActive: true,
            href: '#',
        },
    ];

    return (
        <Main
            breadcrumbs={breadcrumbs}
            breadcrumbsMode="dark"
            errorStatus={error?.response?.status}
        >
            <PageHeader maxWidth="md">
                {data && (
                    <ResolveCaseDialog
                        open={dialogOpen}
                        handleClose={() => setDialogOpen(false)}
                        caseData={data}
                    />
                )}
                <Box sx={{ display: 'flex', color: 'common.white' }}>
                    <Typography
                        variant="h3"
                        component="h1"
                        sx={{ flexGrow: 1 }}
                    >
                        Review request
                    </Typography>
                    {data &&
                        !data.resolveDate &&
                        !appConfig?.IS_READ_ONLY_MAINTENANCE && (
                            <Tooltip
                                title={
                                    <>
                                        <span>
                                            Has your question already been
                                            answered?
                                        </span>
                                        <br />
                                        <span>
                                            Feel free to resolve your request
                                            any time.
                                        </span>
                                    </>
                                }
                            >
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    color="inherit"
                                    onClick={() => setDialogOpen(true)}
                                >
                                    Resolve Request
                                </Button>
                            </Tooltip>
                        )}
                </Box>
            </PageHeader>
            <PageBody maxWidth="md">
                <Box sx={{ mb: 4 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 0.5,
                        }}
                    >
                        {data ? (
                            <>
                                <Typography
                                    variant="h4"
                                    sx={{ wordBreak: 'break-all' }}
                                >
                                    {data?.caseTitle}
                                </Typography>
                                <Typography
                                    variant="label"
                                    labelColor="primary"
                                    sx={{
                                        maxHeight: '20px',
                                        ml: 1,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {data?.caseStatus}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography variant="h4">
                                    <Skeleton
                                        width={300}
                                        aria-label="skeleton"
                                    />
                                </Typography>
                                <Skeleton
                                    width={100}
                                    sx={{
                                        ml: 1,
                                    }}
                                />
                            </>
                        )}
                    </Box>
                    <Typography
                        component="p"
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                        sx={{ fontWeight: 400 }}
                    >
                        {data?.caseNumber || <Skeleton width={200} />}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {data ? (
                            `Created on
                            ${formatedDate(data?.createDate).toDate()} by
                            ${data?.createdBy}`
                        ) : (
                            <Skeleton width={300} />
                        )}
                    </Typography>
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        {data ? 'Details:' : <Skeleton width={70} />}
                    </Typography>

                    <Box sx={{ display: 'flex', mb: 2 }}>
                        {data ? (
                            <>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    sx={{ pr: 1 }}
                                >
                                    {data?.product}
                                </Typography>
                                <Typography variant="body1" sx={{ pr: 1 }}>
                                    &#8226;
                                </Typography>
                                <Typography variant="body1" sx={{ pr: 1 }}>
                                    {data?.productVersion}
                                </Typography>
                                <Typography variant="body1" sx={{ pr: 1 }}>
                                    &#8226;
                                </Typography>
                                <Typography variant="body1" sx={{ pr: 1 }}>
                                    {data?.operatingSystem}
                                </Typography>
                            </>
                        ) : (
                            <Skeleton width={250} />
                        )}
                    </Box>
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ fontWeight: 500 }}
                    >
                        {data ? 'Description:' : <Skeleton width={90} />}
                    </Typography>
                    {data ? (
                        <Typography
                            variant="body1"
                            sx={{
                                wordBreak: 'break-word',
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            {data.caseDescription}
                        </Typography>
                    ) : (
                        <>
                            <Typography variant="body1">
                                <Skeleton width="90%" />
                            </Typography>
                            <Typography variant="body1">
                                <Skeleton width="100%" />
                            </Typography>
                            <Typography variant="body1">
                                <Skeleton width="95%" />
                            </Typography>
                            <Typography variant="body1">
                                <Skeleton width="50%" />
                            </Typography>
                        </>
                    )}
                    {data?.attachments.length > 0 && (
                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ mt: 2, fontWeight: 500 }}
                        >
                            Attachments:
                        </Typography>
                    )}
                    <AttachmentList
                        data={data?.attachments}
                        createdAt={data?.createDate}
                    />
                </Box>
                <Box sx={{ mt: 6, mb: 4 }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        {data ? 'Activity:' : <Skeleton width={70} />}
                    </Typography>
                    {data &&
                        !data.resolveDate &&
                        !appConfig?.IS_READ_ONLY_MAINTENANCE && (
                            <Form initialDraft={draftComment} />
                        )}
                    {data && !comments ? (
                        <ActivityItemLoading />
                    ) : (
                        comments?.comments.map((note: Comment) => (
                            <ActivityItem
                                key={note.id}
                                user={note.createdBy}
                                date={note.createdOn}
                                details={note.description}
                                attachments={note.attachments}
                                isComment
                            />
                        ))
                    )}

                    <ActivityItem
                        date={
                            data &&
                            formatedDate(data.modifyDate).toLocalDateString()
                        }
                        changedTo={data?.caseStatus}
                        isComment={false}
                    />
                </Box>
            </PageBody>
        </Main>
    );
};

export default withAuth(ViewCase);
