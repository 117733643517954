import Grid from '@northstar/core/Grid';
import { AttachmentFile } from 'types/cases';
import Attachment from './Attachment';

interface Props {
    data: AttachmentFile[] | undefined;
    createdAt: string;
}

const AttachmentList = ({ data, createdAt }: Props) => {
    if (!data || data.length === 0) {
        return null;
    }

    return (
        <Grid container spacing={2}>
            {data.map((item: AttachmentFile) => (
                <Grid item xs={12} md={6} key={item.id}>
                    <Attachment
                        altText={item.fileName}
                        fileName={item.fileName}
                        fileSize={item.fileSize}
                        uploadDate={createdAt}
                        id={item.id}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default AttachmentList;
