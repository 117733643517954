import { useState } from 'react';
import { AccountDetails, UserAccountsProducts } from 'types/accounts';
import { useAuth } from 'contexts/Auth';
import { PermissionsProducts } from 'types/cases';
import { UserPermissions } from 'types/permissions';
import { isShownOnPortalFilter } from 'utils/isShownOnPortalFilter';
import { UserProducts } from 'types/products';
import axios from 'utils/axios';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { markPrimaryRequestCompleted } from 'constants/primaryRequest';
import { removeArrayDuplicateObjectsByKey } from 'utils/removeArrayDuplicateObjectsByKey';
import { objectOrderBy } from 'utils/objectOrderBy';
import { processPermissionsProducts } from 'contexts/Accounts/processPermissionsProducts';

export function useAccountsProvide() {
    const { user } = useAuth();

    const [relatedAccounts, setRelatedAccounts] = useState<
        AccountDetails[] | undefined
    >(() => (user?.is_portal_user ? undefined : []));

    const [myProducts, setMyProducts] = useState<
        UserAccountsProducts | undefined
    >(() => (user?.is_portal_user ? undefined : {}));

    const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
    const [permissionsProducts, setPermissionsProducts] =
        useState<PermissionsProducts>({});

    const getAllAccountsIds = (data?: AccountDetails[]) => {
        const accountsIDs = [];

        if (user?.accountId) {
            accountsIDs.push(user.accountId);
        }

        (data || relatedAccounts || []).forEach(({ accountId }) => {
            if (accountId) {
                accountsIDs.push(accountId);
            }
        });

        return accountsIDs;
    };

    const handleUsersProducts = (data: UserProducts) => {
        const newData = {} as UserProducts;

        Object.keys(data).forEach((key) => {
            newData[key] = isShownOnPortalFilter(data[key]);
        });

        setMyProducts(newData);
    };

    const handleResourcePermissions = (userPermissions: UserPermissions) => {
        const { resourcePermissions } = userPermissions;

        const filterKey = 'productName';

        const groupedByAccount = {} as PermissionsProducts;

        resourcePermissions?.supportCases.productPermissions.forEach((item) => {
            (groupedByAccount[item.accountId] ||= []).push(item);
        });

        Object.keys(groupedByAccount).forEach((item) => {
            const duplicatesRemoved =
                removeArrayDuplicateObjectsByKey(
                    groupedByAccount[item],
                    filterKey
                ) || [];

            const orderedByKey = objectOrderBy(duplicatesRemoved, filterKey);

            groupedByAccount[item] = orderedByKey;
        });

        setPermissionsProducts(groupedByAccount);
    };

    const handleSelectedAccounts = (relAccounts: AccountDetails[]) => {
        const sessionItems = sessionStorage.getItem('selectedAccounts');
        const sessionAccountsIds = sessionItems ? sessionItems.split(',') : [];

        if (!sessionAccountsIds.length) {
            const ids = getAllAccountsIds(relAccounts);

            sessionStorage.setItem('selectedAccounts', ids.toString());

            return ids;
        }

        return sessionAccountsIds;
    };

    const mergedPermissionProducts = processPermissionsProducts(
        permissionsProducts,
        selectedAccounts
    );

    const userAccountsDetails = [
        { accountId: user?.accountId, accountName: user?.accountName },
        ...(relatedAccounts || []),
    ] as AccountDetails[];

    const isSingleAccount = userAccountsDetails.length === 1;

    useEffectOnce(() => {
        (async () => {
            if (user?.is_portal_user) {
                const {
                    data: { relatedAccountIds },
                } = await axios<UserPermissions>('/permissions');

                setSelectedAccounts(handleSelectedAccounts(relatedAccountIds));
                setRelatedAccounts(relatedAccountIds || []);
            }

            markPrimaryRequestCompleted();
        })();
    });

    return {
        getAllAccountsIds,
        handleUsersProducts,
        handleResourcePermissions,
        isSingleAccount,
        myProducts,
        permissionsProducts,
        selectedAccounts,
        setSelectedAccounts,
        userAccountsDetails,
        relatedAccounts,
        mergedPermissionProducts,
    };
}
