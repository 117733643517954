import { Box, Divider } from '@northstar/core';
import Container from '@northstar/core/Container';
import { withAuth } from 'components/Auth';
import { Main } from 'components/layouts';
import { useAuth } from 'contexts/Auth';
import { UserPermissions } from 'types/permissions';
import { useAccounts } from 'contexts/Accounts';
import { CaseDataResponse } from 'types/cases';
import useSWR from 'swr';
import { useMemo } from 'react';
import { Main as MainSection, CasesList } from './components';

const breadcrumbs = [
    {
        href: '/',
        title: 'Home',
        isActive: false,
    },
    {
        title: 'Manage Requests',
        isActive: true,
    },
];

const SupportCases = () => {
    const casesAPI = '/cases';
    const permissionsAPI = '/permissions?service=SUPPORT_CASES';

    const { user } = useAuth();

    const {
        data: caseData,
        error,
        mutate: mutateCases,
        isValidating,
        isLoading,
    } = useSWR<CaseDataResponse>(casesAPI);

    const { data: draftCaseData, mutate: mutateDraftCases } =
        useSWR<CaseDataResponse>('/cases/draft');

    const errorStatus = !user?.is_portal_user ? 403 : error?.response?.status;

    const { handleResourcePermissions, mergedPermissionProducts } =
        useAccounts();

    useSWR<UserPermissions>(
        user?.is_portal_user && !mergedPermissionProducts && permissionsAPI,
        { onSuccess: handleResourcePermissions }
    );

    const combinedCases = useMemo(() => {
        if (!draftCaseData && !caseData) {
            return { cases: [] };
        }

        const firstCases = draftCaseData?.cases ? draftCaseData.cases : [];
        const secondCases = caseData?.cases ? caseData.cases : [];
        return { cases: [...firstCases, ...secondCases] };
    }, [draftCaseData, caseData]);

    return (
        <Main
            breadcrumbFullWidth
            breadcrumbs={breadcrumbs}
            errorStatus={errorStatus}
        >
            <MainSection />
            <Box bgcolor="alternate.main">
                <Divider />
                <Box sx={{ pt: 4, pb: 6 }}>
                    <Container
                        maxWidth="xl"
                        sx={{ paddingY: { xs: 2, sm: 4, md: 6 } }}
                    >
                        <CasesList
                            caseData={combinedCases}
                            isValidating={isValidating}
                            isLoading={isLoading}
                            mutateCases={mutateCases}
                            mutateDraftCases={mutateDraftCases}
                            productsList={mergedPermissionProducts}
                        />
                    </Container>
                </Box>
            </Box>
        </Main>
    );
};

export default withAuth(SupportCases);
