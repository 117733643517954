import {
    AnswerOption,
    LookupResponse,
    QuestionAnswerRequest,
    QuestionCriteria,
    QuestionDefinition,
} from 'types/createCase';

type Props = {
    answerOptions: AnswerOption[] | undefined;
    formFields: { [key: string]: string };
    lookUpOptions: LookupResponse[];
    questionCriteria: QuestionCriteria[] | undefined;
    questionDefinitions: QuestionDefinition[] | undefined;
};

const getQuestionAnswerReqData = ({
    answerOptions,
    formFields,
    lookUpOptions,
    questionCriteria,
    questionDefinitions,
}: Props) => {
    if (!answerOptions || !questionCriteria || !questionDefinitions) return [];

    const result: QuestionAnswerRequest[] = [];

    Object.keys(formFields).forEach((fieldName) => {
        const questionCriteriaItem = questionCriteria.find(
            (qCItem) => qCItem.questionId === fieldName
        );

        const questionDefinitionItem = questionDefinitions.find(
            (qDItem) => qDItem.id === fieldName
        );

        if (!questionDefinitionItem) return;

        const { displayOrder = 1 } = questionCriteriaItem || {};

        const {
            lookupSource,
            name: questionText = '',
            target = null,
        } = questionDefinitionItem;

        const lookUpAnswer = lookUpOptions.find(
            (item) => item.id === formFields[fieldName]
        );

        const fieldAnswer = answerOptions.find(
            (aOItem) => aOItem.id === formFields[fieldName]
        );

        const answerText =
            lookupSource && lookUpAnswer
                ? lookUpAnswer.id
                : fieldAnswer?.value || formFields[fieldName];

        const model: QuestionAnswerRequest = {
            answerText,
            displayOrder,
            questionId: fieldName,
            questionText,
            targetEntityAttribute: target,
        };

        result.push(model);
    });

    return result;
};

export { getQuestionAnswerReqData };
