import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import type { Product, ProductDownloads } from 'types/products';
import { ContactFooter } from 'components/CallToAction/ContactFooter';
import LoadingBox from 'components/LoadingBox';
import { Main } from 'components/layouts';
import { withAuth } from 'components/Auth';
import { useAuth } from 'contexts/Auth';
import AccessRestricted from 'components/DataState/AccessRestricted';
import { sortByVersionNumber } from 'utils/sortByVersionNumber';

import ProductOverview from './ProductOverview';
import DownloadProducts from './DownloadProducts';

const getBreadCrumb = (product: Product | undefined) => [
    {
        href: '/',
        title: 'Home',
        isActive: false,
    },
    ...(product
        ? [
              {
                  href: `/${product.slug}`,
                  title: product.name,
                  isActive: false,
              },
              {
                  title: 'Downloads',
                  isActive: true,
              },
          ]
        : []),
];

const getErrorStatus = (productSlugError: any, downloadError: any) =>
    (productSlugError || downloadError)?.response?.status;

const ProductDownloadPage = () => {
    const { productSlug } = useParams();
    const { getProductPermissions } = useAuth();

    const { data: product, error: productSlugError } = useSWR<Product>(
        `/products/slug/${productSlug}`
    );

    const { permissions, isSecured } = product || {};

    const { canDownloads } = getProductPermissions(permissions, isSecured);

    const {
        data: downloads,
        error: downloadError,
        isLoading: downloadsLoading,
    } = useSWR<ProductDownloads[]>(
        canDownloads && product && `/product_downloads/${product.id}`
    );

    const breadcrumbs = getBreadCrumb(product);

    const { latestDownloads = [], previousDownloads = [] } = downloads
        ? downloads.reduce(
              (acc, ver) => {
                  acc[
                      ver.isLatest ? 'latestDownloads' : 'previousDownloads'
                  ].push(ver);

                  return acc;
              },
              {
                  latestDownloads: [] as ProductDownloads[],
                  previousDownloads: [] as ProductDownloads[],
              }
          )
        : {};

    const sortedLatestDownloads = sortByVersionNumber(latestDownloads);
    const sortedPreviousDownloads = sortByVersionNumber(previousDownloads);

    const isLoadingBoxVisible = !product || (canDownloads && !downloads);

    const shouldRenderDownloads =
        product &&
        !downloadsLoading &&
        (sortedLatestDownloads || sortedPreviousDownloads).length > 0;

    return (
        <Main
            breadcrumbs={breadcrumbs}
            errorStatus={getErrorStatus(productSlugError, downloadError)}
        >
            {canDownloads === false && <AccessRestricted />}
            {isLoadingBoxVisible && <LoadingBox minHeight={400} />}
            {product && downloads && (
                <ProductOverview
                    description={product.description}
                    name={product.name}
                />
            )}

            {shouldRenderDownloads && (
                <DownloadProducts
                    latests={sortedLatestDownloads}
                    previous={sortedPreviousDownloads}
                    productId={product.productLineId}
                    productName={product.name}
                />
            )}
            <ContactFooter />
        </Main>
    );
};

export default withAuth(ProductDownloadPage);
