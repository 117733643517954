import { InputAdornment } from '@northstar/core';
import TextField from '@northstar/core/TextField';
import { Search as SearchIcon } from '@northstar/icons';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'utils/debounce';

interface IProps {
    setGlobalFilter: (val: string | undefined) => void;
    globalFilter: string;
}

const TableToolbar = (props: IProps) => {
    const { setGlobalFilter, globalFilter } = props;
    const [searchParams, setSearchParams] = useSearchParams();

    const searchHandler = debounce((query: string) => {
        if (!query) {
            searchParams.delete('search');
            setSearchParams(searchParams);
            setGlobalFilter(undefined);
        } else {
            searchParams.delete('page');
            setGlobalFilter(query);
            searchParams.set('search', query);
            setSearchParams(searchParams);
        }
    });

    return (
        <TextField
            defaultValue={globalFilter || ''}
            onChange={(e) => searchHandler(e.target.value)}
            placeholder="Search"
            variant="outlined"
            size="small"
            sx={{ width: '100%', px: 2, mt: 2 }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default TableToolbar;
