import { Grid } from '@northstar/core';
import { useServiceRequest } from 'hooks/useServiceRequest';
import { Suspense, useState } from 'react';
import {
    AnswerOption,
    LookupResponse,
    QuestionCriteria,
} from 'types/createCase';
import { objectOrderBy } from 'utils/objectOrderBy';
import { DynamicFormField } from 'types/formFields';
import { useFormContext } from 'react-hook-form';
import { FormFieldLoading } from 'components/FormFieldLoading';
import {
    LazyFormAutocomplete,
    LazyFormDateTimePicker,
    LazyFormInput,
} from 'components/FormFields';
import { conditionalValidationFields } from '../validationSchema';
import { removeDescendantFields } from './removeDescendantFields';
import { createFieldState } from './createFieldState';
import { createValidationRules } from './createValidationRules';
import { getFieldStateOptions } from './getFieldStateOptions';
import { getIdToCompare } from './getIdToCompare';
import { getRelevantCriteria } from './getRelevantCriteria';
import { technicalSupportRequestId } from './technicalSupportRequestId';
import { getChainedFields } from './getChainedFields';

type Props = {
    dynamicFormFields: DynamicFormField[];
    lookUpOptions: LookupResponse[];
    productBrandId: string;
    setDynamicFormFields: (fields: DynamicFormField[]) => void;
    setLookUpOptions: (options: LookupResponse[]) => void;
    setShowConditionalFields: (value: boolean) => void;
    updateFormValidation: (removedFields: any, validation: any) => void;
};

const DynamicFormFields = ({
    dynamicFormFields,
    lookUpOptions,
    setLookUpOptions,
    setShowConditionalFields,
    updateFormValidation,
    setDynamicFormFields,
    productBrandId,
}: Props) => {
    const { questionCriteria, questionDefinitions, answerOptions } =
        useServiceRequest();
    const [loadingField, setLoadingField] = useState(false);

    const {
        getValues: getFormValues,
        setValue: setFieldValue,
        unregister,
    } = useFormContext();

    const formValues = getFormValues();

    const removeUnusedFormValues = (
        ids: string[],
        fields: DynamicFormField<AnswerOption>[]
    ) => {
        const newValues = fields.map((item) => item.props.name);

        ids.forEach((key) => {
            if (!newValues.includes(key)) {
                unregister(key);
            }
        });
    };

    const initNewFormValues = (fields: DynamicFormField[]) => {
        fields.forEach((item) => {
            const fieldValue =
                formValues[item.props.name] || item.defaultValue || '';

            setFieldValue(item.props.name, fieldValue);
        });
    };

    const createNewFormFields = async (
        relevantCriteria: QuestionCriteria[]
    ) => {
        const formFieldsPromises = relevantCriteria.map(async (qCItem) => {
            const qDefinition = (questionDefinitions || []).find(
                (qDItem) => qDItem.id === qCItem.questionId
            );

            if (!qDefinition) return undefined;

            const fieldState = createFieldState(qCItem, qDefinition);

            if (fieldState.elementType === 'dropDown') {
                if (!lookUpOptions.length && qDefinition.lookupSource) {
                    setLoadingField(true);
                }

                const { fieldStateOptions, lookUpResponse } =
                    await getFieldStateOptions({
                        answerOptions,
                        definition: qDefinition,
                        lookUpOptions,
                        productBrandId,
                        questionId: qCItem.questionId,
                        selectedAccount: formValues.selectedAccount,
                    });

                fieldState.props.options = fieldStateOptions;

                if (lookUpResponse) {
                    setLookUpOptions(lookUpResponse);
                }

                setLoadingField(false);
            }

            return fieldState;
        });

        return (await Promise.all(formFieldsPromises)).filter(
            Boolean
        ) as DynamicFormField<AnswerOption>[];
    };

    const processNonTechnicalSupportItem = async (
        value: AnswerOption,
        removedFieldIds: string[],
        remainingFormFields: DynamicFormField[]
    ) => {
        const idToCompare = getIdToCompare(value, answerOptions);

        const relevantCriteria = getRelevantCriteria(
            idToCompare,
            questionCriteria
        );

        const newFormFields = await createNewFormFields(relevantCriteria);

        const chainedFormFields = [] as DynamicFormField[];

        newFormFields.forEach((fieldItem) => {
            if (!formValues[fieldItem.props.name]) return;

            const optItem = {
                id: formValues[fieldItem.props.name],
                questionId: fieldItem.props.name,
            } as AnswerOption;

            const chained = getChainedFields({
                startOption: optItem,
                dynamicFormFields,
                formValues,
            });

            chainedFormFields.push(...chained);
        });

        const newValidation = createValidationRules(newFormFields);

        updateFormValidation(
            [...removedFieldIds, 'productVersion', 'operatingSystem'],
            newValidation
        );

        setShowConditionalFields(false);

        initNewFormValues(newFormFields);

        const orderedByKey = objectOrderBy(
            [...remainingFormFields, ...newFormFields, ...chainedFormFields],
            'displayOrder'
        );

        setDynamicFormFields(orderedByKey);

        removeUnusedFormValues(removedFieldIds, newFormFields);
    };

    const processTechnicalSupportItem = (
        removedFieldIds: string[],
        fields: DynamicFormField[]
    ) => {
        updateFormValidation(removedFieldIds, conditionalValidationFields);
        removeUnusedFormValues(removedFieldIds, fields);
        setDynamicFormFields(fields);
        setShowConditionalFields(true);
    };

    const handleDynamicFieldSelect = ({
        data,
    }: {
        name: string;
        data: AnswerOption | null;
    }) => {
        if (!data?.id) return;

        const { remainingFormFields, removedFieldIds } = removeDescendantFields(
            dynamicFormFields,
            data.questionId
        );

        if (data.id === technicalSupportRequestId) {
            processTechnicalSupportItem(removedFieldIds, remainingFormFields);
        } else {
            processNonTechnicalSupportItem(
                data,
                removedFieldIds,
                remainingFormFields
            );
        }
    };

    const renderField = (field: DynamicFormField) => {
        const { elementType, props } = field;

        switch (elementType) {
            case 'input':
                return (
                    <Suspense fallback={<FormFieldLoading />}>
                        <LazyFormInput {...props} />
                    </Suspense>
                );
            case 'dropDown':
                return (
                    <Suspense fallback={<FormFieldLoading />}>
                        <LazyFormAutocomplete
                            {...props}
                            handleOnChange={handleDynamicFieldSelect}
                        />
                    </Suspense>
                );
            case 'date':
            case 'time':
            case 'dateTime':
                return (
                    <Suspense fallback={<FormFieldLoading />}>
                        <LazyFormDateTimePicker
                            {...props}
                            elementType={elementType}
                        />
                    </Suspense>
                );
            default:
                return null;
        }
    };

    if (dynamicFormFields.length === 0) return null;

    return (
        <>
            <>
                {dynamicFormFields.map((item) => (
                    <Grid key={item.props.name} item xs={12}>
                        {renderField(item)}
                    </Grid>
                ))}
            </>
            {loadingField && (
                <Grid item xs={12}>
                    <FormFieldLoading />
                </Grid>
            )}
        </>
    );
};

export { DynamicFormFields };
