import { FormikHelpers, useFormik } from 'formik';
import Box from '@northstar/core/Box';
import Divider from '@northstar/core/Divider';
import Avatar from '@northstar/core/Avatar';
import axios from 'utils/axios';
import { useApp } from 'contexts/App';
import { useParams } from 'react-router-dom';
import { useAuth } from 'contexts/Auth';
import { mutate, useSWRConfig } from 'swr';
import { string, object, mixed } from 'libs/validation';
import { Dropzone } from 'components/Dropzone';
import { useCallback, useEffect, useRef } from 'react';
import { Button } from 'components/Button';
import { MESSAGE_ZIPPING_FILES } from 'constants/file';
import { useScrollToInvalidField } from 'hooks/useScrollToInvalidField';
import { DraftCommentType, useDraftSave } from 'hooks/useDraftSave';
import { HTMLEditor } from 'components/HTMLEditor';
import { DraftStatus } from '../DraftElements/DraftStatus';
import { DraftDeleteButton } from '../DraftElements/DraftDeleteButton';

interface Values {
    description: string;
    attachments?: File[];
}

const maxCharsLimit = 100_000;

const validationSchema = object({
    description: string().trim().min(2).max(maxCharsLimit).required(),
    attachments: mixed().attachments(),
});

interface Props {
    initialDraft?: DraftCommentType;
}

const Form = ({ initialDraft }: Props) => {
    const formItemRef = useRef<HTMLFormElement>(null);

    const { cache } = useSWRConfig();
    const { user, updateToken } = useAuth();
    const { addNotification } = useApp();
    const { id } = useParams();
    const commentEndpoint = `/cases/draft/${id}/comment`;
    const {
        draftSaveStatus,
        draftIsDeleteing,
        draftData,
        setDraftData,
        draftIsChangeing,
        uploadCommentAttachment,
        files,
        setFiles,
        saveDraftComment,
        deleteDraftComment,
        removeCommentAttachment,
        reuploadCommentAttachment,
        acceptMaxFiles,
    } = useDraftSave(initialDraft, id);

    const initialValues = {
        description: '',
    };

    const onSubmit = async (values: Values, actions: FormikHelpers<Values>) => {
        const payload = validationSchema.cast(values) as Values;

        try {
            updateToken(-1);
            actions.setSubmitting(true);
            const { description } = payload;
            const data = await axios.post(`/cases/${id}/comment`, {
                description,
                draftCommentId: draftData?.id,
            });

            if (data.status === 201) {
                addNotification({
                    message: 'Note added successfully',
                    status: 201,
                });
                mutate(
                    `/cases/${id}/comments`,
                    (
                        { comments }: { comments: Array<Comment> } = {
                            comments: [],
                        }
                    ) => ({
                        comments: [data.data, ...comments],
                    }),
                    false
                );
                cache.delete(commentEndpoint);
                actions.resetForm();
                setFiles([]);
                setDraftData(undefined);
            }
        } catch (e: any) {
            addNotification({
                message: e.message,
                status: e.response.status,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
    });

    const { isSubmitting, errors } = formik;

    useScrollToInvalidField({
        isSubmitting,
        errors,
        container: formItemRef.current,
    });

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            await formik.setFieldValue('attachments', [
                ...files,
                ...acceptMaxFiles(acceptedFiles, files),
            ]);
            uploadCommentAttachment(acceptedFiles);
        },
        [acceptMaxFiles, files, formik, uploadCommentAttachment]
    );

    const hnadleRemoveFileDrop = async (file: File) => {
        await removeCommentAttachment(file);
        formik.setFieldValue('attachments', files);
    };

    const handleChange = (value: string) => {
        formik.setFieldValue('description', value);

        saveDraftComment(commentEndpoint, {
            description: value,
        });
    };

    const deleteComment = () => {
        deleteDraftComment();
        formik.resetForm();
    };

    const retryUploadFile = async (file: File) => {
        await reuploadCommentAttachment(file);
        formik.validateField('attachments');
    };

    const actionDisable = !!(
        formik.isSubmitting ||
        draftIsChangeing ||
        draftIsDeleteing
    );

    const fieldError = formik.touched.description
        ? formik.errors.description
        : undefined;

    const resetEditorContent = !!(
        initialDraft?.description && draftData === undefined
    );

    useEffect(() => {
        if (draftData && !formik.values.attachments) {
            formik.setFieldValue('attachments', draftData.attachments);
        }

        if (draftData && !formik.values.description) {
            formik.setFieldValue('description', draftData.description);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draftData]);

    return (
        <form onSubmit={formik.handleSubmit} ref={formItemRef}>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Avatar
                    sx={{
                        width: 36,
                        height: 36,
                        bgcolor: 'primary.main',
                        mr: 2,
                    }}
                    alt={user?.name}
                    src="./images/avatar.jpg"
                />
                <Box sx={{ width: 'calc(100% - 52px)' }}>
                    <HTMLEditor
                        fieldError={fieldError}
                        onChange={handleChange}
                        placeholder="Comment on this request..."
                        initialValue={draftData?.description || ''}
                        resetContent={
                            resetEditorContent || draftData === undefined
                        }
                        readonly={formik.isSubmitting || draftIsDeleteing}
                    />

                    <Box sx={{ my: 2 }}>
                        <Dropzone
                            onDrop={onDrop}
                            label="Attach Files"
                            info={MESSAGE_ZIPPING_FILES}
                            disabled={actionDisable}
                            errors={formik.errors.attachments}
                            files={files}
                            onRemove={hnadleRemoveFileDrop}
                            retryUpload={retryUploadFile}
                        />
                    </Box>
                    <Divider />
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{ mt: 2 }}
                        color="text.secondary"
                    >
                        <Button
                            variant="contained"
                            disabled={
                                !!(
                                    formik.isSubmitting ||
                                    draftIsChangeing ||
                                    draftIsDeleteing
                                )
                            }
                            isLoading={formik.isSubmitting}
                            type="submit"
                            sx={{
                                mr: 1.5,
                            }}
                        >
                            Submit
                        </Button>
                        <DraftStatus status={draftSaveStatus} />
                        <DraftDeleteButton
                            hasDraft={!!draftData}
                            disabled={actionDisable}
                            onClick={deleteComment}
                        />
                    </Box>
                </Box>
            </Box>
            <Divider />
        </form>
    );
};

export default Form;
