import { useEffect, useRef, useState } from 'react';
import axios from 'utils/axios';
import { debounce } from 'utils/debounce';
import { useApp } from 'contexts/App';
import { mutate, useSWRConfig } from 'swr';
import { getUniqueFiles, removeFile } from 'components/Dropzone';
import { Case, CaseData } from 'types/cases';
import { canUploadFiles } from './utils/canUploadFiles';
import { acceptMaxFiles } from './utils/acceptMaxFiles';
import { mutateStateFile } from './utils/mutateStateFile';
import { getUploadProgress } from './utils/getUploadProgress';
import { getError } from './utils/getError';
import { s3FilesToFileList } from './utils/s3FilesToFileList';
import { useHandleBeforeUnload } from './utils/useHandleBeforeUnload';
import { useFileProgressBlocker } from './utils/useFileProgressBlocker';

export type SaveStatusType = 'saving' | 'saved' | undefined;

export interface DraftCommentType {
    id: string;
    description: string;
    attachments: File[];
    createdBy: string;
    createdOn: Date;
}
export interface DraftCaseType
    extends Omit<CaseData & DraftCommentType, 'attachments'> {
    attachments: File[];
}

export const useDraftSave = (
    initialValues?: DraftCaseType | DraftCommentType,
    caseId?: string
) => {
    const { addNotification } = useApp();
    const [draftSaveStatus, setDraftSaveStatus] = useState<SaveStatusType>();
    const [draftIsDeleteing, setDraftIsDeleteing] = useState<boolean>(false);
    const [draftData, setDraftData] = useState(initialValues);
    const [draftIsChangeing, setDraftIsChangeing] = useState(false);
    const { cache } = useSWRConfig();
    const [files, setFiles] = useState<File[]>([]);
    const firstLoadData = useRef<DraftCaseType | DraftCommentType>();
    const [newDraft, setNewDraft] = useState({
        isNew: true,
        attempts: 0,
    });

    const deleteHandler = async (endpoint: string, cacheEndpoint?: string) => {
        try {
            setDraftIsDeleteing(true);
            const data = await axios.delete(endpoint);

            if (data.status === 204) {
                firstLoadData.current = undefined;
                setDraftData(undefined);
                setFiles([]);
                cache.delete(cacheEndpoint || endpoint);
            }
        } catch (e: any) {
            const { message, status } = getError(e);
            addNotification({
                message,
                status,
            });
        } finally {
            setDraftIsDeleteing(false);
        }
    };

    const uploadFile = async (
        uploadFiles: File[],
        endpoint: string,
        mutateEndpoint?: string
    ) => {
        let uploadedCount = 0;
        if (!canUploadFiles(files, uploadFiles)) {
            return;
        }
        uploadFiles.forEach(async (file: any) => {
            const controller = new AbortController();
            Object.assign(file, {
                abortController: controller,
            });
            try {
                if (file.status === 'error') {
                    return;
                }
                const formData = new FormData();
                formData.append('attachment', file);
                const response = await axios.post(endpoint, formData, {
                    headers: {
                        skipCancelation: true,
                    },
                    onUploadProgress: (progressEvent) => {
                        setDraftIsChangeing(true);
                        setFiles((prevFiles) =>
                            mutateStateFile(prevFiles, file, {
                                progress: getUploadProgress(progressEvent),
                            })
                        );
                    },
                    signal: controller.signal,
                });

                setFiles((prevFiles) =>
                    mutateStateFile(prevFiles, file, {
                        id: response.data.id,
                        abortController: null,
                    })
                );
            } catch (e: any) {
                const { message } = getError(e);
                setFiles((prevFiles) =>
                    mutateStateFile(prevFiles, file, {
                        status: 'error',
                        errorMessage: message,
                        uploaded: 'failed',
                    })
                );
            } finally {
                setDraftIsChangeing(false);
                uploadedCount += 1;
                if (uploadedCount === uploadFiles.length) {
                    mutate(mutateEndpoint || endpoint);
                }
            }
        });
    };

    const uploadHandler = async (
        acceptedFiles: File[],
        endpoint: string,
        mutateEndpoint?: string
    ) => {
        const uniqueArray = getUniqueFiles(
            acceptMaxFiles(acceptedFiles, files),
            files
        );
        setFiles((prevFiles) => [...prevFiles, ...uniqueArray]);
        uploadFile(uniqueArray, endpoint, mutateEndpoint || endpoint);
    };

    const handleRemoveFile = async (
        file: File,
        endpoint: string,
        mutateEndpoint?: string
    ) => {
        setDraftIsChangeing(true);
        const { id, abortController } = file as any;

        if (!id && abortController) {
            abortController.abort();
        }

        if (id) {
            try {
                const data = await axios.delete(endpoint, {
                    headers: {
                        skipCancelation: true,
                    },
                });

                if (data?.status === 204) {
                    await mutate(mutateEndpoint);
                    setFiles(removeFile(files, file));
                }
                setDraftIsChangeing(false);
            } catch (e: any) {
                const { message } = getError(e);
                setDraftIsChangeing(false);
                Object.assign(file, {
                    status: 'error',
                    errorMessage: message,
                });
            }
        } else {
            setFiles(removeFile(files, file));
            setDraftIsChangeing(false);
        }
    };

    const retryUpload = async (
        file: any,
        endpoint: string,
        mutateEndpoint?: string
    ) => {
        try {
            Object.assign(file, {
                status: 'success',
                uploaded: 'retry',
                errorMessage: null,
            });
            uploadFile([file], endpoint, mutateEndpoint);
        } catch (e: any) {
            const { message } = getError(e);
            Object.assign(file, {
                status: 'error',
                errorMessage: message,
                uploaded: 'failed',
            });
        }
    };

    // Draft comment
    const createDraftComment = async (
        endpoint: string,
        body: any,
        mutateEndpoint?: string
    ) => {
        let data;
        try {
            setDraftIsChangeing(true);
            setDraftSaveStatus('saving');
            const response = await axios.post(endpoint, body);
            if (response.status === 200) {
                const { data: responseData } = response;
                setDraftData(responseData);
                setDraftSaveStatus('saved');
                mutate(mutateEndpoint || endpoint, { ...responseData });
                data = responseData;
            }
        } catch (e: any) {
            const { message, status } = getError(e);
            addNotification({
                message,
                status,
            });
        } finally {
            setDraftIsChangeing(false);
            setTimeout(() => {
                setDraftSaveStatus(undefined);
            }, 2000);
        }
        return data;
    };

    const createComment = useRef(debounce(createDraftComment, 2000)).current;

    const saveDraftComment = (
        endpoint: string,
        body: any,
        mutateEndpoint?: string
    ): Promise<DraftCommentType> => {
        setDraftIsChangeing(true);
        return createComment(endpoint, body, mutateEndpoint);
    };

    const deleteDraftComment = () => {
        const commentEndpoint = `/cases/draft/${caseId}/comment`;
        return deleteHandler(
            `/cases/draft/comment/${draftData?.id}`,
            commentEndpoint
        );
    };

    const createEmptyDradtComment = async () => {
        setDraftIsChangeing(true);
        return createDraftComment(`/cases/draft/${caseId}/comment`, {
            description: '',
        });
    };

    const uploadCommentAttachment = async (acceptedFiles: File[]) => {
        let createEmptyDraft;
        if (!draftData) {
            createEmptyDraft = await createEmptyDradtComment();
        }
        const commentId = createEmptyDraft?.id || draftData?.id;
        const url = `/cases/draft/comments/${commentId}/attachment`;
        const commentEndpoint = `/cases/draft/${caseId}/comment`;
        return uploadHandler(acceptedFiles, url, commentEndpoint);
    };

    const reuploadCommentAttachment = async (file: File) => {
        let createEmptyDraft;
        if (!draftData) {
            createEmptyDraft = await createEmptyDradtComment();
        }
        const commentId = createEmptyDraft?.id || draftData?.id;
        return retryUpload(
            file,
            `/cases/draft/comments/${commentId}/attachment`,
            `/cases/draft/${caseId}/comment`
        );
    };

    const removeCommentAttachment = (file: File) => {
        return handleRemoveFile(
            file,
            `/cases/draft/attachments/${(file as any)?.id}`,
            `/cases/draft/${caseId}/comment`
        );
    };

    // Draft case
    const createDraftCase = async (
        endpoint: string,
        body: any,
        method: string = 'POST',
        mutateEndpoint?: string
    ) => {
        let responseData: any;
        try {
            setDraftIsChangeing(true);
            setDraftSaveStatus('saving');
            const { data } = await axios({
                method,
                url: endpoint,
                data: body,
                headers: {
                    portalAccountIds: body.selectedAccount,
                },
            });
            responseData = data;
            if (responseData?.id) {
                if (!draftData?.id || draftData?.id !== responseData.id) {
                    window.history.replaceState(
                        null,
                        '',
                        `#${responseData.id}`
                    );
                }
                setDraftData(data);
                mutate(
                    mutateEndpoint || endpoint,
                    (
                        { cases }: { cases: Array<CaseData> } = {
                            cases: [],
                        }
                    ) => {
                        const existingCase = cases.some(
                            (c) => c.id === responseData.id
                        );

                        const updatedCases = cases.map((c) => {
                            return c.id === responseData.id ? responseData : c;
                        });
                        !existingCase && updatedCases.unshift(responseData);
                        return {
                            cases: updatedCases,
                        };
                    }
                );
            }
        } catch (e: any) {
            const { message, status } = getError(e);
            addNotification({
                message,
                status,
            });
            setDraftIsChangeing(false);
            setDraftSaveStatus(undefined);
        } finally {
            setTimeout(() => {
                setDraftSaveStatus('saved');
                setDraftIsChangeing(false);
                setDraftSaveStatus(undefined);
            }, 2000);
        }
        return responseData;
    };

    const createCase = useRef(debounce(createDraftCase, 2000)).current;

    const isNewDraft = () => newDraft.isNew && newDraft.attempts === 0;

    const saveDraftCase = (body: any) => {
        let respons;
        let url = '/cases/draft';
        if (draftData?.id) {
            url = `${url}/${draftData?.id}`;
        }

        if (newDraft.attempts < 1) {
            setNewDraft((prev) => {
                return {
                    ...prev,
                    attempts: prev.attempts + 1,
                };
            });
            respons = createDraftCase(url, body);
        }

        setDraftIsChangeing(true);

        if (!isNewDraft() && draftData?.id) {
            respons = createCase<DraftCaseType>(
                url,
                body,
                'PUT',
                '/cases/draft'
            );
        }
        return respons;
    };

    const deleteDraftCase = async () => {
        const endpoint = `/cases/draft/${draftData?.id}`;
        let responseData: any;
        try {
            setDraftIsDeleteing(true);
            const data = await axios.delete(endpoint);
            if (data.status === 204) {
                setDraftData(undefined);
                setFiles([]);
                mutate(
                    '/cases/draft',
                    (
                        { cases }: { cases: Array<CaseData> } = {
                            cases: [],
                        }
                    ) => {
                        const updatedCases = cases?.filter(
                            (c) => c.id !== draftData?.id
                        );

                        return {
                            cases: updatedCases,
                        };
                    }
                );
                mutate(endpoint, undefined, { revalidate: false });

                window.history.replaceState(null, '', ' ');
                setNewDraft({ isNew: true, attempts: 0 });
            }
            responseData = data;
        } catch (e: any) {
            const { message, status } = getError(e);
            addNotification({
                message,
                status,
            });
        } finally {
            setDraftIsDeleteing(false);
        }
        return responseData;
    };

    const uploadCaseAttachment = async (acceptedFiles: File[], body: Case) => {
        let createEmptyDraft: any;
        if (!draftData?.id) {
            createEmptyDraft = await saveDraftCase(body);
        }
        const id = createEmptyDraft?.id || draftData?.id;
        const url = `/cases/draft/${id}/attachments`;
        uploadHandler(acceptedFiles, url, `/cases/draft/${id}`);
        return id;
    };

    const reuploadCaseAttachment = async (file: File, body: Case) => {
        let createEmptyDraft;
        if (!draftData?.id) {
            createEmptyDraft = await createDraftCase('/cases/draft', body);
        }
        const id = createEmptyDraft?.id || draftData?.id;
        retryUpload(
            file,
            `/cases/draft/${id}/attachments`,
            `/cases/draft/${draftData?.id}`
        );
        return id;
    };

    const removeCaseAttachment = (file: File) => {
        return handleRemoveFile(
            file,
            `/cases/draft/attachments/${(file as any)?.id}`,
            `/cases/draft/${draftData?.id}`
        );
    };

    useEffect(() => {
        if (!initialValues || firstLoadData.current) return;
        if (!firstLoadData.current) {
            const attachments = s3FilesToFileList(
                initialValues?.attachments || []
            ) as unknown as File[];
            firstLoadData.current = { ...initialValues, attachments };
            setDraftData({ ...initialValues, attachments });
            setFiles(attachments);
            setNewDraft({ isNew: false, attempts: 1 });
        }
    }, [initialValues]);

    useHandleBeforeUnload(draftIsChangeing);

    useFileProgressBlocker(draftIsChangeing, setDraftIsChangeing, files);

    return {
        initialDraftData: firstLoadData.current,
        draftSaveStatus,
        draftIsDeleteing,
        draftData,
        setDraftData,
        draftIsChangeing,
        files,
        setNewDraft,
        setFiles,
        saveDraftComment,
        uploadCommentAttachment,
        reuploadCommentAttachment,
        removeCommentAttachment,
        deleteDraftComment,
        acceptMaxFiles,
        saveDraftCase,
        deleteDraftCase,
        uploadCaseAttachment,
        reuploadCaseAttachment,
        removeCaseAttachment,
    };
};
