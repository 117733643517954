import Typography from '@northstar/core/Typography';
import Grid from '@northstar/core/Grid';
import Link from '@northstar/core/Link';
import Container from '@northstar/core/Container';
import useSWR from 'swr';
import type { ContactList } from 'types/contactUs';
import Skeleton from '@northstar/core/Skeleton';

const LoadingContacts = () => (
    <Grid spacing={3}>
        {Array.from(Array(5).keys()).map((index) => {
            return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Skeleton
                        aria-label="skeleton"
                        sx={{
                            width: '100%',
                            my: 1,
                        }}
                    />
                    <Skeleton
                        aria-label="skeleton"
                        sx={{
                            width: '100%',
                            my: 1,
                        }}
                    />
                    <Skeleton
                        aria-label="skeleton"
                        sx={{
                            width: '80%',
                            my: 1,
                        }}
                    />
                </Grid>
            );
        })}
    </Grid>
);

const ContactUsDetails = () => {
    const { data: detailsData } = useSWR<ContactList>('/hs_contact_details');

    return (
        <Container sx={{ paddingY: 5 }}>
            <Typography marginBottom={2} variant="h4" gutterBottom>
                Contact details
            </Typography>
            {!detailsData ? (
                <LoadingContacts />
            ) : (
                <>
                    {detailsData.email && (
                        <Typography
                            variant="body1"
                            color="textPrimary"
                            gutterBottom
                            sx={{
                                fontWeight: 500,
                            }}
                        >
                            Email :{' '}
                            <Link href={`mailto:${detailsData.email}`}>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    component="span"
                                >
                                    {detailsData.email}
                                </Typography>
                            </Link>
                        </Typography>
                    )}
                    <Grid spacing={3} sx={{ marginTop: 0 }}>
                        {detailsData.phoneNumbers.map((item) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={item.geoLocation}
                                sx={{
                                    paddingTop: '0 !important',
                                    paddingBottom: 3,
                                }}
                            >
                                {item.country && (
                                    <Typography variant="h6">
                                        {item.country}
                                    </Typography>
                                )}
                                {item.geoLocation && (
                                    <Typography
                                        variant="subtitle1"
                                        color="primary"
                                        gutterBottom
                                        sx={{
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        {item.geoLocation}
                                    </Typography>
                                )}
                                {item.tollFreeNumber && (
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        gutterBottom
                                        sx={{
                                            fontWeight: 500,
                                        }}
                                    >
                                        Toll Free :{' '}
                                        <Link
                                            href={`tel:${item.tollFreeNumber}`}
                                        >
                                            <Typography
                                                variant="body1"
                                                color="textSecondary"
                                                component="span"
                                            >
                                                {item.tollFreeNumber}
                                            </Typography>
                                        </Link>
                                    </Typography>
                                )}
                                {item.phoneNumber && (
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        gutterBottom
                                        sx={{
                                            fontWeight: 500,
                                        }}
                                    >
                                        Phone :{' '}
                                        <Link href={`tel:${item.phoneNumber}`}>
                                            <Typography
                                                variant="body1"
                                                color="textSecondary"
                                                component="span"
                                            >
                                                {item.phoneNumber}
                                            </Typography>
                                        </Link>
                                    </Typography>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </Container>
    );
};

export { ContactUsDetails };
