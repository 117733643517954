import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Container } from 'components/Container';
import type { Product } from 'types/products';
import { ContactFooter } from 'components/CallToAction/ContactFooter';
import Typography from '@northstar/core/Typography';
import Divider from '@northstar/core/Divider';
import { useAuth } from 'contexts/Auth';
import LoadingBox from 'components/LoadingBox';
import { Main } from 'components/layouts';
import AccessRestricted from 'components/DataState/AccessRestricted';
import ProductInfo from './components/ProductInfo';
import LoginBanner from './components/LoginBanner';

const ProductPage = () => {
    const { user } = useAuth();
    const { productSlug } = useParams();

    const { data: product, error } = useSWR<Product>(
        `/products/slug/${productSlug}`
    );

    const breadcrumbs = [
        {
            href: '/',
            title: 'Home',
            isActive: false,
        },
        {
            title: product?.name || '',
            isActive: true,
        },
    ];

    const isRestricted = product
        ? Object.values(product.permissions || {}).filter(Boolean).length === 0
        : false;

    return (
        <Main breadcrumbs={breadcrumbs} errorStatus={error?.response?.status}>
            {!product ? (
                <LoadingBox minHeight={400} />
            ) : (
                <>
                    <Container>
                        <Typography variant="h3" component="h1">
                            {product.name}
                        </Typography>

                        {product.description && (
                            <Typography
                                variant="h5"
                                color="textSecondary"
                                sx={{ fontWeight: 'normal', marginTop: '10px' }}
                            >
                                {product.description}
                            </Typography>
                        )}
                    </Container>
                    <Container paddingY={0}>
                        <Divider />
                    </Container>
                    {!user && product.isSecured && (
                        <LoginBanner productName={product.name} />
                    )}

                    {user && product.isSecured && isRestricted && (
                        <AccessRestricted />
                    )}

                    {(!product.isSecured || (!isRestricted && user)) && (
                        <ProductInfo
                            productPermissions={product.permissions}
                            isSecured={product.isSecured}
                        />
                    )}
                </>
            )}

            <ContactFooter />
        </Main>
    );
};

export default ProductPage;
